import React, { useEffect, useState } from 'react';
import Modal from '../Common/Modal';
import { deviceWidth } from '@/utils/deviceWidth';
import { useDispatch, useSelector } from 'react-redux';
import { roundnessCalculator } from '@/components/DesignSystem/AtomicComponents/util';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  saveDetailedItemById,
  saveProductLoaderId,
  setVariantModalVisibility,
} from 'src/redux/actions';
import VariantRadioGroup from './../VariantRadioGroup';
import { selectedVariantFromVariantList } from '@/utils/selectedVariantFromVariantList';
import { itemIndexFromCart } from '@/utils/itemIndexFromCart';
import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import { toastNotifyInfo } from '../Common/Toast';
import { CartButton } from '../DesignSystem/AtomicComponents';
import { InteractionComponent } from '../DesignSystem/AtomicComponents/CartButton/CartButton.styles';
import { MinusIcon } from '@/assets/svgExports/MinusIcon';
import { PlusIcon } from '@/assets/svgExports/PlusIcon';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import { IS_CLIENT } from '@/utils/checkRenderEnv';
import { useCartHandlers } from '../../hooks/useCartHandler';

const VariantModal = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [activeVariant, setActiveVariant] = useState({
    variantIndex: -1,
    variantId: -1,
  });

  const {
    handleIncreamentClick,
    handleDecreamentClick,
    handleSetProductData,
    handleSetState,
    onPersonalizeProductClick,
    handleSetProductCustomizationCount,
  } = useCartHandlers();

  /*
   state created to handle cart changes that are not saved.
   for eg, if user adds quantity of variant but does't save the change/ add to cart,
   then global cart data will not get changed
   */
  const [localCartData, setLocalCartData] = useState({});

  const { store_info, theme, store_id } = useSelector(
    (state) => state.storeReducer.store
  );
  const { userData, storeInfo, cartData } = useSelector((state) => ({
    userData: state.userReducer,
    storeInfo: state.storeReducer.store,
    cartData: state.cartReducer,
  }));
  const {
    variant: { variantModal },
    detailedItem,
    productLoaderId,
  } = useSelector((state) => state.commonReducer);

  /*
    below useEffect is for saving index and id of first available
    variant of the specific item
  */
  useEffect(() => {
    if (variantModal && detailedItem) {
      const activeVariantIndex = detailedItem?.variants?.findIndex(
        (el) => el.available === 1 && el.status !== 2
      );

      setActiveVariant((state) => ({
        ...state,
        variantIndex: activeVariantIndex,
        variantId: detailedItem?.variants?.[activeVariantIndex]?.variant_id ?? -1,
      }));

      handleSetState?.(activeVariantIndex);

      // store cartData in local state
      if (cartData) {
        setLocalCartData(cartData);
      }
    }
  }, [variantModal, detailedItem]);
  useEffect(() => {
    if (variantModal && detailedItem === null) {
      dispatch(
        fetchCatalogItemById({ store_id: store_id, item_id: productLoaderId }, true)
      );
    }

    if (variantModal === false) {
      setActiveVariant({
        variantIndex: -1,
        variantId: -1,
      });
      if (
        document.getElementsByTagName('body')[0] &&
        document.getElementsByTagName('body')[0].style
      ) {
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
      }
    } else {
      if (
        document.getElementsByTagName('body')[0] &&
        document.getElementsByTagName('body')[0].style
      ) {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      }
    }
  }, [variantModal, detailedItem]);

  const itemIndexInCart = itemIndexFromCart(localCartData, detailedItem);

  const onVariantSelect = (selectedInd, item) => {
    if (item.available) {
      setActiveVariant((state) => ({
        ...state,
        variantIndex: selectedInd,
        variantId: detailedItem?.variants?.[selectedInd].variant_id,
      }));
      handleSetState(selectedInd);
    } else toastNotifyInfo('Variant not available!');
  };

  const onClose = () => {
    dispatch(setVariantModalVisibility(false));
    dispatch(saveDetailedItemById(null));
    dispatch(saveProductLoaderId(null));
  };

  const onAddToCartBtnHandle = () => {
    if (activeVariant.variantIndex === -1) return;

    if (!getCombinedQuantityOfSelectedVariants()) {
      handleCart('add');
    }
    props.isRedirect && router.push(getRoute(PATH.CART, store_info?.domain));
  };
  const handleCart = (type) => {
    if (type === 'inc' && !qtyLessThanAvailQty()) {
      toastNotifyInfo('No more units available for purchase');
      return;
    }

    dispatch(saveProductLoaderId(detailedItem.id));
    dispatch(saveDetailedItemById(detailedItem));

    let newCart = [];
    type = type === 'add' || type === 'inc' ? 'inc' : 'dec';

    newCart = mutateVariantItemWithCartData(
      type,
      detailedItem,
      detailedItem.variants[activeVariant.variantIndex],
      localCartData
    );
    setLocalCartData(newCart);
    dispatch(saveCart(newCart));
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeInfo?.store_id,
          domain: storeInfo?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
  };

  // get sum of all quantity of selected variant
  const getCombinedQuantityOfSelectedVariants = () => {
    let sumVariantQuantity = 0;
    if (
      Object.keys(localCartData).length &&
      localCartData?.items?.[itemIndexInCart]?.variants_selected?.length
    ) {
      sumVariantQuantity = localCartData?.items[
        itemIndexInCart
      ]?.variants_selected.reduce((acc, curr) => acc + curr.quantity, 0);
    }
    return sumVariantQuantity;
  };

  /**
   * utility function to get quantity and current item data of product
   * @return {*} obj
   */
  const returnItemData = () => {
    let itemData = {
      quantity: 0,
      selectedItem: null,
    };

    if (itemIndexInCart >= 0) {
      if (localCartData?.items?.[itemIndexInCart]?.variants_selected?.length) {
        const selectedVariant = selectedVariantFromVariantList(
          localCartData,
          itemIndexInCart,
          activeVariant.variantId
        );
        itemData = {
          quantity: selectedVariant?.quantity ?? 0,
          selectedItem: selectedVariant ? selectedVariant : null,
        };
      } else {
        itemData = {
          quantity: localCartData?.items?.[itemIndexInCart]?.quantity,
          selectedItem: localCartData?.items?.[itemIndexInCart],
        };
      }
    }
    return itemData;
  };

  /**
   * Check if quantity is less than available qty
   * @return {*} boolean
   */
  const qtyLessThanAvailQty = () => {
    const { selectedItem } = returnItemData();
    if (selectedItem && !!Object.keys(selectedItem).length) {
      // only work if available qty is not 0
      if (selectedItem?.available_quantity) {
        return selectedItem.quantity < selectedItem.available_quantity;
      }
    }
    return true;
  };
  const LeftCartButton = () => (
    <InteractionComponent
      position="left:14px;"
      width="30px"
      onClick={(e) => {
        e.stopPropagation();
        if (detailedItem?.product_personalization) {
          IS_CLIENT && LocalStorageHelper.add('activeVariantId', activeVariant.variantId);
          handleSetProductData(detailedItem);
          handleDecreamentClick('dec', detailedItem);
          dispatch(setVariantModalVisibility(false));
        } else {
          handleCart('dec');
        }
      }}
    >
      <MinusIcon />
    </InteractionComponent>
  );
  const RightCartButton = () => (
    <InteractionComponent
      position="right:14px;"
      width="30px"
      onClick={(e) => {
        e.stopPropagation();
        if (detailedItem?.product_personalization) {
          IS_CLIENT && LocalStorageHelper.add('activeVariantId', activeVariant.variantId);
          handleSetProductData(detailedItem);
          handleIncreamentClick('inc', detailedItem);
          dispatch(setVariantModalVisibility(false));
        } else {
          handleCart('inc');
        }
      }}
    >
      <span className={qtyLessThanAvailQty() ? 'o-100' : 'o-20'}>
        <PlusIcon />
      </span>
    </InteractionComponent>
  );

  const handlePPAddToCart = () => {
    IS_CLIENT && LocalStorageHelper.add('activeVariantId', activeVariant.variantId);
    dispatch(setVariantModalVisibility(false));
    handleSetProductData(detailedItem);
    handleSetProductCustomizationCount(detailedItem);
    onPersonalizeProductClick();
  };

  return (
    variantModal && (
      <Modal
        visible={variantModal}
        customStyles={{
          paddingLeft: 0,
          paddingRight: 0,
          maxWidth: deviceWidth <= 766 ? '100%' : 480,
        }}
        animation={'slideUp'}
        className="bottom-modal"
        onClose={onClose}
      >
        <div className="variantModal">
          <div className="variantModal-section" style={{ border: 'none' }}>
            <span className="variantModal-closeicon" onClick={onClose}>
              <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
            </span>
            <h3>Choose One</h3>
          </div>

          <div className="variant-list">
            <VariantRadioGroup
              itemLowQty={detailedItem?.low_quantity}
              itemList={detailedItem?.variants || []}
              activeIdx={activeVariant.variantIndex}
              onChange={onVariantSelect}
            />
          </div>

          <div className="add-to-cart flex justify-between">
            {itemIndexInCart === -1 && detailedItem?.product_personalization ? (
              <div
                className="tw-flex tw-w-full tw-cursor-pointer tw-justify-center tw-py-[0.875rem] tw-text-[15px]"
                onClick={handlePPAddToCart}
                style={{
                  backgroundColor: theme?.colors?.primary_color,
                  borderRadius: theme ? `${roundnessCalculator(theme.roundness)}` : '8px',
                  color: '#FFFF',
                }}
              >
                {storeInfo?.theme?.cta_config?.pdp_personalized_cta ||
                  'Personalise and buy'}
              </div>
            ) : (
              <>
                <div className="w-100 mr1 flex justify-center left-0">
                  <CartButton
                    leftButton={<LeftCartButton />}
                    rightButton={<RightCartButton />}
                    fontSize="15"
                    roundness={theme?.roundness}
                    onChange={(type) => {
                      detailedItem?.product_personalization
                        ? handlePPAddToCart()
                        : handleCart(type);
                    }}
                    // sending null to handle 0 quantity issue during render
                    quantity={returnItemData().quantity}
                    px="5px"
                    py="14px"
                    label="Add to Cart"
                    borderColor={'#00000000'}
                    style={{
                      justifyContent: 'center',
                      width: deviceWidth > 540 ? '75%' : '100%',
                    }}
                  />
                </div>
                <div className="w-100 ml1 right-0">
                  {!getCombinedQuantityOfSelectedVariants() ? (
                    <button
                      className="pointer"
                      style={{
                        backgroundColor: `${
                          theme ? theme?.colors?.primary_color : '#EA5151'
                        }`,
                        borderRadius: `${
                          theme ? `${roundnessCalculator(theme?.roundness)}` : '8px'
                        }`,
                        opacity: '1',
                      }}
                      onClick={onAddToCartBtnHandle}
                    >
                      {getCustomCtaText('buy_now_cta')}
                    </button>
                  ) : (
                    <button
                      className="pointer"
                      style={{
                        backgroundColor: '#369d00',
                        borderRadius: `${
                          theme ? `${roundnessCalculator(theme?.roundness)}` : '8px'
                        }`,
                        opacity: '1',
                      }}
                      onClick={() => {
                        dispatch(setVariantModalVisibility(false));
                        router.push(getRoute(PATH.CART, store_info?.domain));
                      }}
                    >
                      Go to Cart
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    )
  );
};

export default VariantModal;
